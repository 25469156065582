import React, { useCallback, useState, useEffect } from "react";
import {
  AppProvider,
  Card,
  FormLayout,
  Button,
  DisplayText,
  Image,
  TextField,
  Link,
  Toast,
  Modal,
  TextStyle,
} from "@shopify/polaris";
import axios from "axios";
import history from "../../Assets/Lib/history";
import Cookies from "js-cookie";
import logo from "../../Assets/Images/logo.svg";
import ReCAPTCHA from "react-google-recaptcha";

function Login() {
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordError, setrPasswordError] = useState("");
  const [active, setActive] = useState(false);
  const [popupActive, setPopupActive] = useState(false);
  const [isRecaptchaChecked, setIsRecaptchaChecked] = useState(false);
  const [error, setError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const handleEmailFieldChange = useCallback((value) => {
    setEmailValue(value);
    setEmailError("");
  }, []);
  const handlePasswordFieldChange = useCallback((value) => {
    setPasswordValue(value);
    setrPasswordError("");
  }, []);

  useEffect(() => {
    Cookies.set("token", "null");
  }, []);

  const toastMarkup = active ? (
    <Toast
      content="These credentials do not match our records."
      onDismiss={toggleActive}
    />
  ) : null;

  function onChange(value) {
    console.log(value);
    setCaptchaValue(value);
    setIsRecaptchaChecked(true);
    setError("");
  }

  return (
    <AppProvider>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "40%",
          transform: "translate(-50%, -50%)",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Image src={logo} alt="Logo" />

        <div style={{ minWidth: 400, marginTop: 20 }}>
          <div style={{ marginBottom: 30 }}>
            <DisplayText size="large">Login</DisplayText>
          </div>

          <FormLayout>
            <Card sectioned>
              <FormLayout>
                <TextField
                  type="email"
                  label="Email"
                  value={emailValue}
                  onChange={handleEmailFieldChange}
                  error={emailError}
                />
                <div onKeyDown={handleKeyPress}>
                  <TextField
                    label="Password"
                    value={passwordValue}
                    onChange={handlePasswordFieldChange}
                    type="password"
                    error={passwordError}
                  />
                </div>
                {/* <div style={{ display: "flex", justifyContent: "center" }}>
                  <ReCAPTCHA
                    sitekey="6Lf49GEeAAAAALtqQYO9yyfwrpH1xJGuoPqbVCjL"
                    onChange={onChange}
                  />
                </div> */}
                <TextStyle variation="negative">{error}</TextStyle>
                <Button fullWidth primary onClick={handleSignIn}>
                  Sign in
                </Button>
                {/* <Link url="/forgotpassword">Forgot password?</Link> */}
              </FormLayout>
            </Card>
            <div>
              <div style={{ float: "left" }}>
                <p style={{ float: "left" }}>
                  {"© Copyright " +
                    new Date().getFullYear() +
                    " " +
                    process.env.REACT_APP_CLIENT_NAME}
                </p>
                <br />
              </div>
              <div style={{ float: "right" }}></div>
            </div>
          </FormLayout>
        </div>
        {toastMarkup}
        <Modal open={popupActive} loading={true}></Modal>
      </div>
    </AppProvider>
  );

  function handleSignIn(e) {
    if (
      !emailValue ||
      (emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) ||
      !passwordValue
    ) {
      if (!emailValue) {
        setEmailError("Please enter your email");
      }
      if (
        emailValue &&
        !emailValue.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        setEmailError("Please enter a valid email");
      }
      if (!passwordValue) {
        setrPasswordError("Please enter your password");
      }
      // if (!isRecaptchaChecked) {
      //   setError("Please verify that you are not a robot.");
      // }
    } else {
      setPopupActive(true);
      const form_data = new FormData();
      form_data.append("email", emailValue);
      form_data.append("password", passwordValue);
      // form_data.append("g-recaptcha-response", captchaValue);
      axios
        .post(`${process.env.REACT_APP_BASE_URL}admin/v1/auth/login`, form_data)
        .then((res) => {
          console.log(res.data);
          Cookies.set("un-game-password", passwordValue);
          Cookies.set("un-game-accesstoken", res.data.token, { expires: 7 });
          res?.data?.user?.name &&
            Cookies.set("un-game-name", res.data.user.name);
          // res?.data?.last_name &&
          //   // Cookies.set("un-game-ln", res.data.last_name);
          history.push("admin/students");
        })
        .catch(function (error) {
          // window.grecaptcha.reset();
          // setIsRecaptchaChecked("false");
          setPopupActive(false);
          setError("Invalid email or password");
        });
    }
  }

  function toggleActive() {
    setActive((active) => !active);
  }
  function handleKeyPress(event) {
    const enterKeyPressed = event.keyCode === 13;
    if (enterKeyPressed) {
      handleSignIn();
    }
  }
}
export default Login;
