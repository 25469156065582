import React, { useCallback, useState } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Page,
  TextStyle,
  Select,
  PageActions,
  Loading,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";

export default function ResetQuestion() {
  const [counterValue, setCounterValue] = useState("");
  const handleChangeCounter = useCallback((newValue) => {
    setCounterValue(newValue);
    setCounterError("");
  });
  const [selectedGroup, setSelectedGroup] = useState("0");
  const handleSelectChangeGroup = useCallback((value) => {
    setSelectedGroup(value);
  });
  const optionsGroup = [
    { label: "None", value: "0" },
    { label: "Group 1", value: "1" },
    { label: "Group 2", value: "2" },
    { label: "Group 3", value: "3" },
    { label: "Group 4", value: "4" },
    { label: "Group 5", value: "5" },
  ];
  const [counterError, setCounterError] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  return (
    <NavigationLayout>
      <Page
        title="Reset Question"
        breadcrumbs={[{ url: "/admin/reset-buttons" }]}
      >
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">Counter</TextStyle>
            <TextField
              value={counterValue}
              onChange={handleChangeCounter}
              autoComplete="off"
              error={counterError}
              type="number"
            />
            <TextStyle variation="strong">Group</TextStyle>
            <Select
              options={optionsGroup}
              onChange={handleSelectChangeGroup}
              value={selectedGroup}
              placeholder="Please select a group"
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Submit",
            onClick: handleSubmit,
            loading: isSaving && true,
          }}
        />
      </Page>
    </NavigationLayout>
  );
  function handleSubmit() {
    if (counterValue === "") {
      counterValue === "" && setCounterError("Please enter a counter");
    } else {
      setIsSaving(true);
      const form_data = new FormData();

      counterValue !== "" && form_data.append("counter_id", counterValue);
      selectedGroup !== "" &&
        form_data.append(
          "group_id",
          selectedGroup === "0" ? null : selectedGroup
        );
      axios
        .post("admin/v1/questions/reset", form_data)
        .then((res) => {
          setIsSaving(false);
          //history.push("/admin/students");
        })
        .catch((err) => console.log(""));
    }
  }
}
