import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Select,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import RichEditor from "@methodexists/me-rich-editor";
const Add = () => {
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = [
    "imahandleButtonClickge/gif",
    "image/jpeg",
    "image/png",
  ];
  const [hoverImagePreview, setHoverImagePreview] = useState("");
  const [normalImagePreview, setNormalImagePreview] = useState("");
  const [hoverImageError, setHoverImageError] = useState("");
  const [normalImageError, setNormalImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setHoverImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setHoverImageError("");
  };

  const [filesNormal, setFilesNormal] = useState([]);
  const handleDropZoneNormal = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesNormal((filesNormal) => [...filesNormal, ...acceptedFiles]),
    []
  );
  const fileUploadNormal = !filesNormal.length && <DropZone.FileUpload />;
  let uploadedFilesNormal = filesNormal.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesNormal[filesNormal.length - 1].name}
        source={
          validImageTypes.indexOf(filesNormal[filesNormal.length - 1].type) > 0
            ? window.URL.createObjectURL(filesNormal[filesNormal.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesNormal[filesNormal.length - 1].name}{" "}
        <Caption>{filesNormal[filesNormal.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageNormal = (filesNormal) => {
    setNormalImagePreview(
      validImageTypes.indexOf(filesNormal[filesNormal.length - 1].type) > 0
        ? window.URL.createObjectURL(filesNormal[filesNormal.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setNormalImageError("");
    setMessageError("");
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
  }, []);
  const [titleFrValue, setTitleFrValue] = useState("");
  const handleChangeTitleFr = useCallback((newValue) => {
    setTitleFrValue(newValue);
  }, []);
  const [embassyValue, setEmbassyValue] = useState("");
  const handleChangeEmbassy = useCallback((newValue) => {
    setEmbassyValue(newValue);
    setMessageError("");
  }, []);
  const [nameValue, setNameValue] = useState("");
  const handleChangeName = useCallback((newValue) => {
    setNameValue(newValue);
    setNameError("");
    setMessageError("");
  });

  const [nameError, setNameError] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const handleSelectChangeGroup = useCallback((value) => {
    setSelectedGroup(value);
    setGroupError("");
    setMessageError("");
  });
  const optionsGroup = [
    { label: "Group 1", value: "1" },
    { label: "Group 2", value: "2" },
    { label: "Group 3", value: "3" },
    { label: "Group 4", value: "4" },
  ];

  const [selectedPlayer, setSelectedPlayer] = useState("");
  const handleSelectChangePlayer = useCallback((value) => {
    setSelectedPlayer(value);
    setPlayerError("");
    setMessageError("");
  });
  const optionsPlayer = [
    { label: "Player 13", value: "13" },
    { label: "Player 14", value: "14" },
    { label: "Player 15", value: "15" },
    { label: "Player 16", value: "16" },
    { label: "Player 17", value: "17" },
    { label: "Player 18", value: "18" },
    { label: "Player 19", value: "19" },
    { label: "Player 20", value: "20" },
  ];
  const [playerError, setPlayerError] = useState("");
  const [groupError, setGroupError] = useState("");
  const [messageError, setMessageError] = useState("");
  return (
    <Page title="Add Ambassador" breadcrumbs={[{ url: "/admin/ambassadors" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={normalImagePreview ? normalImagePreview : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneNormal}
                    onDropAccepted={sendImageNormal}
                  >
                    {uploadedFilesNormal}
                    {fileUploadNormal}
                  </DropZone>
                </div>
              </div>
              <InlineError message={normalImageError} />
              <TextStyle variation="strong">Name</TextStyle>
              <TextField
                value={nameValue}
                onChange={handleChangeName}
                autoComplete="off"
                error={nameError}
              />
              <FormLayout.Group>
                {/* <FormLayout>
                  <TextStyle variation="strong">Group</TextStyle>
                  <Select
                    options={optionsGroup}
                    onChange={handleSelectChangeGroup}
                    value={selectedGroup}
                    error={groupError}
                    placeholder="Please select a group"
                  />
                </FormLayout> */}
                <FormLayout>
                  <TextStyle variation="strong">Player</TextStyle>
                  <Select
                    options={optionsPlayer}
                    onChange={handleSelectChangePlayer}
                    value={selectedPlayer}
                    error={playerError}
                    placeholder="Please select a player"
                  />
                </FormLayout>
              </FormLayout.Group>
              <TextStyle variation="strong">Embassy</TextStyle>
              <TextField
                value={embassyValue}
                onChange={handleChangeEmbassy}
                autoComplete="off"
              />
              <InlineError message={messageError} />
            </FormLayout>
          </FormLayout.Group>
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
      />
    </Page>
  );

  function handleSave() {
    if (
      !filesNormal.length ||
      !nameValue ||
      selectedGroup === "" ||
      selectedPlayer === ""
    ) {
      !filesNormal.length && setNormalImageError("Please upload an image");
      !nameValue && setNameError("Please enter a name");
      selectedGroup === "" && setGroupError("Please select a group");
      selectedPlayer === "" && setPlayerError("Please select a player");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      filesNormal.length &&
        form_data.append("image", filesNormal[filesNormal.length - 1]);
      nameValue && form_data.append("name", nameValue);
      selectedGroup !== "" && form_data.append("group_id", "5");
      selectedPlayer !== "" && form_data.append("player_id", selectedPlayer);
      embassyValue && form_data.append("embassy", embassyValue);
      axios
        .post("admin/v1/ambassadors", form_data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            history.push("/admin/ambassadors");
          } else {
            setIsSaving(false);
            setMessageError(res.data.data);
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Add;
