import React, { useCallback, useState, useEffect } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Page,
  TextStyle,
  Select,
  PageActions,
  Loading,
  Toast,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";

export default function AddPoint() {
  const [selectedPhase, setSelectedPhase] = useState("");
  const handleSelectChangePhase = useCallback((value) => {
    setSelectedPhase(value);
    setPhaseError("");
  });
  const optionsPhase = [
    { label: "Phase 1", value: "1" },
    { label: "Phase 3", value: "3" },
  ];
  const [phaseError, setPhaseError] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const handleSelectChangeStudent = useCallback((value) => {
    setSelectedStudent(value);
    setStudentError("");
  });

  const [optionsStudent, setOptionsStudent] = useState([]);
  const [studentError, setStudentError] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content="Point added successfully" onDismiss={toggleActive} />
  ) : null;
  useEffect(() => {
    axios
      .get(`admin/v1/students?per_page=1000000`)
      .then((result) => {
        result?.data?.data?.data &&
          setOptionsStudent(
            result.data.data.data.map((item, index) => {
              return {
                label: item.name,
                value: String(item.id),
              };
            })
          );
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <NavigationLayout>
      <Page title="Add Point Student">
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <TextStyle variation="strong">Student</TextStyle>
            <Select
              options={optionsStudent}
              onChange={handleSelectChangeStudent}
              value={selectedStudent}
              placeholder="Please select a Student"
              error={studentError}
            />
            <TextStyle variation="strong">Phase</TextStyle>
            <Select
              options={optionsPhase}
              onChange={handleSelectChangePhase}
              value={selectedPhase}
              placeholder="Please select a Phase"
              error={phaseError}
            />
          </FormLayout>
        </Card>
        <PageActions
          primaryAction={{
            content: "Submit",
            onClick: handleSubmit,
            loading: isSaving && true,
          }}
        />
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
  function handleSubmit() {
    if (selectedStudent === "" || selectedPhase === "") {
      selectedStudent === "" && setStudentError("Please select a student");
      selectedPhase === "" && setPhaseError("Please select a phase");
    } else {
      setIsSaving(true);
      const form_data = new FormData();

      selectedStudent !== "" && form_data.append("student_id", selectedStudent);
      selectedPhase !== "" && form_data.append("phase_id", selectedPhase);
      axios
        .post("admin/v1/students/points/add", form_data)
        .then((res) => {
          setIsSaving(false);
          toggleActive();
          //history.push("/admin/students");
        })
        .catch((err) => console.log(""));
    }
  }
}
