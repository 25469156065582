import React, { useCallback, useState, useEffect } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Page,
  TextStyle,
  Select,
  PageActions,
  Loading,
  Toast,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";

export default function GetAnswers() {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const handleSelectChangeGroup = useCallback((value) => {
    setSelectedGroup(value);
    setGroupError("");
  });
  const optionsGroup = [
    { label: "Group 1", value: "1" },
    { label: "Group 2", value: "2" },
    { label: "Group 3", value: "3" },
    { label: "Group 4", value: "4" },
    { label: "Group 5", value: "5" },
  ];
  const [groupError, setGroupError] = useState("");

  const [selectedQuestion, setSelectedQuestion] = useState("");
  const handleSelectChangeQuestion = useCallback((value) => {
    setSelectedQuestion(value);
    setQuestionError("");
  });
  const optionsQuestion = [
    { label: "Question 1", value: "1" },
    { label: "Question 2", value: "2" },
    { label: "Question 3", value: "3" },
    { label: "Question 4", value: "4" },
    { label: "Question 5", value: "5" },
    { label: "Question 6", value: "6" },
    { label: "Question 7", value: "7" },
    { label: "Question 8", value: "8" },
    { label: "Question 9", value: "9" },
    { label: "Question 10", value: "10" },
    { label: "Question 11", value: "11" },
    { label: "Question 12", value: "12" },
    { label: "Question 13", value: "13" },
    { label: "Question 14", value: "14" },
    { label: "Question 15", value: "15" },
    { label: "Question 16", value: "16" },
    { label: "Question 17", value: "17" },
    { label: "Question 18", value: "18" },
    { label: "Question 19", value: "19" },
    { label: "Question 20", value: "20" },
  ];
  const [questionError, setQuestionError] = useState("");
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content="Point added successfully" onDismiss={toggleActive} />
  ) : null;
  const [result, setResult] = useState([]);
  useEffect(() => {
    axios
      .get(
        `admin/v1/groups/${selectedGroup}/questions/${selectedQuestion}/answers`
      )
      .then((result) => {
        console.log(result.data.data);
        result.data.data && setResult(result.data.data);
      })
      .catch((err) => console.log(err));
  }, [selectedGroup, selectedQuestion]);

  const switchAnswer = (param) => {
    switch (String(param)) {
      case "1":
        return "A";
      case "2":
        return "B";
      case "3":
        return "C";
      case "-":
        return "-";
      // code block
    }
  };
  return (
    <NavigationLayout>
      <Page title="Get Answers">
        {isSaving ? <Loading /> : null}
        <Card sectioned>
          <FormLayout>
            <FormLayout.Group>
              <Select
                label="Group"
                options={optionsGroup}
                onChange={handleSelectChangeGroup}
                value={selectedGroup}
                placeholder="Please select a group"
                error={groupError}
              />
              <Select
                label="Question"
                options={optionsQuestion}
                onChange={handleSelectChangeQuestion}
                value={selectedQuestion}
                placeholder="Please select a question"
                error={questionError}
              />
            </FormLayout.Group>

            {result.map((element, index) => {
              return (
                <Card.Section key={index}>
                  <FormLayout>
                    <TextStyle>Student: {element.student}</TextStyle>
                    <TextStyle>
                      Answer: {switchAnswer(element.answer)}
                    </TextStyle>
                    <TextStyle>Status: {element.status}</TextStyle>
                  </FormLayout>
                </Card.Section>
              );
            })}
          </FormLayout>
        </Card>

        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
}
