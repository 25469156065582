import React, { useCallback, useState, useEffect } from "react";
import {
  DataTable,
  Button,
  Page,
  Filters,
  Image,
  DisplayText,
  ChoiceList,
  Loading,
  Card,
  TextStyle,
  ButtonGroup,
  Spinner,
  Thumbnail,
  Icon,
  Select,
  FormLayout,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import emptyListImage from "../../Assets/Images/emptyList.svg";
import Pagination from "@material-ui/lab/Pagination";
import EllipsisText from "react-ellipsis-text";
import { ArrowUpMinor, ArrowDownMinor } from "@shopify/polaris-icons";

const List = () => {
  const perPage = 20;
  const [selected, setSelected] = useState("0");

  const handleSelectChange = (value) => {
    setSelected(value);
    // setQueryValue(value);
    setAvailability("group_id");
  };

  const options = [
    { label: "All", value: "0" },
    { label: "Group 1", value: "1" },
    { label: "Group 2", value: "2" },
    { label: "Group 3", value: "3" },
    { label: "Group 4", value: "4" },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [isListEmpty, setIsListEmpty] = useState(false);
  const [items, setItems] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [availability, setAvailability] = useState("");
  const [queryValue, setQueryValue] = useState("");
  const [page, setPage] = useState(1);
  const [sortState, setSortState] = useState("-id");
  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const handleSort = () =>
    sortState === "id" ? setSortState("-id") : setSortState("id");
  const handleEdit = (e) => {
    history.push(`/admin/students/${e.currentTarget.id}`);
  };
  const handleDown = (e) => {
    const bodyObj = {
      sort: "down",
    };
    axios
      .patch(`admin/v1/articles/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const handleUp = (e) => {
    const bodyObj = {
      sort: "up",
    };
    axios
      .patch(`admin/v1/articles/${e.currentTarget.id}/sort`, bodyObj)
      .then((res) => {
        getData();
      })
      .catch((err) => console.log(""));
  };
  const getData = () => {
    axios
      .get(
        `/admin/v1/students?per_page=${perPage}&page=${page}&filter[${
          availability == "" ? "name" : availability
        }]=${
          availability === "group_id" && selected !== "0"
            ? selected
            : queryValue
        }`
      )
      .then((result) => {
        console.log(result.data.data);
        setIsLoading(false);
        !result.data.data.data.length
          ? setIsListEmpty(true)
          : setIsListEmpty(false);
        setTotalPages(Math.ceil(result.data.data.total / perPage));
        result?.data?.data?.data &&
          setItems(
            result.data.data.data.map((item, index) => [
              item.id,
              item?.image && <Thumbnail source={item.image}></Thumbnail>,
              item?.name && item.name,
              item?.group_id !== null && `Group ${item.group_id}`,
              item?.player_id !== null && `Player ${item.player_id}`,
              item?.phase1_score !== null && item?.phase1_score,
              item?.phase3_score !== null && item?.phase3_score,
              <ButtonGroup>
                {/* <Button id={String(item.id)} onClick={(e) => handleUp(e)}>
                  <Icon source={ArrowUpMinor}></Icon>
                </Button>
                <Button id={String(item.id)} onClick={(e) => handleDown(e)}>
                  <Icon source={ArrowDownMinor}></Icon>
                </Button> */}
                <Button onClick={handleEdit} id={item.id}>
                  Edit
                </Button>
              </ButtonGroup>,
            ])
          );
      })
      .catch((err) => setIsLoading(false));
  };
  useEffect(() => {
    getData();
  }, [page, sortState, availability, queryValue, selected]);

  function handleFiltersQueryChange(queryValue) {
    setQueryValue(queryValue);
  }
  function handleAvailabilityChange(availability) {
    setAvailability(availability);
  }
  const handleAvailabilityRemove = useCallback(() => setAvailability(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAvailabilityRemove();
    handleQueryValueRemove();
  }, [handleAvailabilityRemove, handleQueryValueRemove]);

  const filters = [
    {
      key: "availability",
      label: "Filter by",
      filter: (
        <ChoiceList
          title="Filter by"
          titleHidden
          choices={[
            { label: "ID", value: "id" },
            { label: "Name", value: "name" },
          ]}
          selected={availability || []}
          onChange={handleAvailabilityChange}
        />
      ),
      shortcut: true,
    },
  ];

  const appliedFilters = [];
  if (!isEmpty(availability)) {
    const key = "availability";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, availability),
      onRemove: handleAvailabilityRemove,
    });
  }

  const loadingMarkup = isLoading ? <Loading /> : null;
  const emtyState = isListEmpty ? (
    <div
      style={{
        display: "flex",
        justifyContent: "middle",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ width: "25%" }}>
        <Image src={emptyListImage}></Image>
      </div>
      <DisplayText size="small">No results found</DisplayText>
      <br />
    </div>
  ) : (
    isLoading && (
      <div className="spinnerContainer">
        <div className="vertical-center">
          <Spinner size="large" />
        </div>
      </div>
    )
  );

  return (
    <Page
      fullWidth
      title="Students"
      primaryAction={{
        content: "Add Student",
        url: "/admin/students/new",
      }}
    >
      <Card>
        {loadingMarkup}

        <Card.Section>
          <FormLayout>
            <Select
              label="Filter by Group"
              options={options}
              onChange={handleSelectChange}
              value={selected}
            />

            <Filters
              queryValue={queryValue}
              filters={filters}
              appliedFilters={appliedFilters}
              onQueryChange={handleFiltersQueryChange}
              onQueryClear={handleQueryValueRemove}
              onClearAll={handleFiltersClearAll}
            />
          </FormLayout>
        </Card.Section>
        <DataTable
          columnContentTypes={["text", "text", "text", "text"]}
          headings={[
            <TextStyle variation="strong">ID</TextStyle>,
            <TextStyle variation="strong">Thumbnail</TextStyle>,
            <TextStyle variation="strong">Name</TextStyle>,
            <TextStyle variation="strong">Group</TextStyle>,
            <TextStyle variation="strong">Player Id</TextStyle>,
            <TextStyle variation="strong">Phase 1 Score</TextStyle>,
            <TextStyle variation="strong">Phase 3 Score</TextStyle>,
            <TextStyle variation="strong"></TextStyle>,
          ]}
          rows={items}
          sortable={[false, false, false, false]}
          defaultSortDirection="descending"
          initialSortColumnIndex={0}
          onSort={handleSort}
          verticalAlign="middle"
        />

        {emtyState}
        {totalPages >= 2 && (
          <div
            style={{
              paddingTop: "10px",
              textAlign: "center",
              paddingBottom: "10px",
            }}
          >
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              size="large"
            />
          </div>
        )}
      </Card>
    </Page>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "taggedWith":
        return `Tagged with ${value}`;
      case "availability":
        return `Filter by ${value}`;
      // case "productType":
      //   return value.join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};

export default List;
