import React, { useCallback, useState } from "react";
import {
  FormLayout,
  TextField,
  Card,
  Page,
  TextStyle,
  Select,
  PageActions,
  Loading,
  Button,
  Toast,
  Modal,
  TextContainer,
} from "@shopify/polaris";
import NavigationLayout from "../Components/NavigationLayout";
import axios from "../Assets/Lib/axios";
import history from "../Assets/Lib/history";
import { useRef } from "react";

export default function ResetButtons() {
  const reference = useRef("");
  const [isSaving, setIsSaving] = useState(false);
  const [active, setActive] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toastMarkup = active ? (
    <Toast content={toastContent} onDismiss={toggleActive} />
  ) : null;
  const [isB1Loading, setIsB1Loading] = useState(false);
  const [isB2Loading, setIsB2Loading] = useState(false);
  const [isBThreeLoading, setIsBThreeLoading] = useState(false);
  const [isB3Loading, setIsB3Loading] = useState(false);
  const [isB4Loading, setIsB4Loading] = useState(false);
  const [isB5Loading, setIsB5Loading] = useState(false);
  const [isB6Loading, setIsB6Loading] = useState(false);
  const [isB7Loading, setIsB7Loading] = useState(false);
  const [isB8Loading, setIsB8Loading] = useState(false);
  const [activePopup, setActivePopup] = useState(false);
  const [isYesLoading, setIsYesLoading] = useState(false);
  const handleChangePopup = useCallback(
    () => setActivePopup(!activePopup),
    [activePopup]
  );
  const activator = <Button onClick={handleChangePopup}>Open</Button>;
  const handleButtonClick = (url, buttonName) => {
    setActivePopup(true);
  };
  const handleYes = () => {
    let buttonName = reference.current.split("_")[1];
    let url = reference.current.split("_")[0];
    setIsYesLoading(true);
    // buttonName === "phase1" && setIsB1Loading(true);
    // buttonName === "phase2" && setIsB2Loading(true);
    // buttonName === "group1" && setIsB3Loading(true);
    // buttonName === "group2" && setIsB4Loading(true);
    // buttonName === "group3" && setIsB5Loading(true);
    // buttonName === "group4" && setIsB6Loading(true);
    // buttonName === "game" && setIsB7Loading(true);
    // buttonName === "students" && setIsB8Loading(true);

    axios
      .get(url)
      .then((res) => {
        setActivePopup(false);
        setToastContent(res.data.data);
        toggleActive();
        setIsYesLoading(false);
        // buttonName === "phase1" && setIsB1Loading(false);
        // buttonName === "phase2" && setIsB2Loading(false);
        // buttonName === "group1" && setIsB3Loading(false);
        // buttonName === "group2" && setIsB4Loading(false);
        // buttonName === "group3" && setIsB5Loading(false);
        // buttonName === "group4" && setIsB6Loading(false);
        // buttonName === "game" && setIsB7Loading(false);
        // buttonName === "students" && setIsB8Loading(false);
      })
      .catch((err) => console.log(""));
  };
  return (
    <NavigationLayout>
      <Page title="Reset Buttons">
        {isSaving ? <Loading /> : null}
        <Card>
          <Card.Section title="Reset Phases">
            <FormLayout.Group>
              <Button
                primary
                fullWidth
                loading={isB1Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/phase/1";
                }}
              >
                Reset Phase 1
              </Button>
              <Button
                fullWidth
                primary
                loading={isB2Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/phase/2";
                }}
              >
                Reset Phase 2
              </Button>
              <Button
                fullWidth
                primary
                loading={isBThreeLoading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/phase/3";
                }}
              >
                Reset Phase 3
              </Button>
            </FormLayout.Group>
          </Card.Section>
          <Card.Section title="Reset Groups">
            <FormLayout.Group>
              <Button
                primary
                fullWidth
                loading={isB3Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/group/1_group1";
                }}
              >
                Reset Group 1
              </Button>
              <Button
                primary
                fullWidth
                loading={isB4Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/group/2_group2";
                }}
              >
                Reset Group 2
              </Button>
            </FormLayout.Group>
            <FormLayout.Group>
              <Button
                primary
                fullWidth
                loading={isB5Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/group/3_group3";
                }}
              >
                Reset Group 3
              </Button>
              <Button
                primary
                fullWidth
                loading={isB6Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/group/4_group4";
                }}
              >
                Reset Group 4
              </Button>
            </FormLayout.Group>
          </Card.Section>
          <Card.Section>
            <FormLayout.Group>
              <Button
                primary
                fullWidth
                loading={isB7Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/game_game";
                }}
              >
                Reset Game
              </Button>
              <Button
                primary
                fullWidth
                loading={isB7Loading}
                onClick={() => history.push("/admin/reset-question")}
              >
                Reset Question
              </Button>
              {/* <Button
                fullWidth
                primary
                loading={isB8Loading}
                onClick={() => {
                  setActivePopup(true);
                  reference.current = "admin/v1/reset/students_students";
                }}
              >
                Reset Students
              </Button> */}
            </FormLayout.Group>
          </Card.Section>
        </Card>
        <Modal
          // activator={activator}
          open={activePopup}
          onClose={handleChangePopup}
          title={`Are you sure you want to reset ${
            reference.current.split("_")[1]
          }?`}
          primaryAction={{
            content: "Yes",
            onAction: handleYes,
            loading: isYesLoading,
          }}
          secondaryActions={[
            {
              content: "No",
              onAction: handleChangePopup,
            },
          ]}
        ></Modal>
        {toastMarkup}
      </Page>
    </NavigationLayout>
  );
}
