import React, { useEffect } from "react";
import axios from "axios";
import history from "./history";
import Cookies from "js-cookie";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      history.push("/");
      Cookies.set("un-game-accesstoken", null);
    } else {
      return Promise.reject(error);
    }
  }
);
axios.defaults.proxy = process.env.REACT_APP_BASE_URL;
instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${Cookies.get("un-game-accesstoken")}`;
  return config;
});

export default instance;
