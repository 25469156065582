import React, { useCallback, useState } from "react";
import "@shopify/polaris/dist/styles.css";
import { Switch, Route, Router } from "react-router-dom";
import history from "./Assets/Lib/history";
import { AppProvider, Toast } from "@shopify/polaris";
import "./Assets/Styles/globals.css";
import "./Assets/Styles/react-tagsinput.css";
import Login from "./Pages/Authentication/Login";
import ForgotPassword from "./Pages/Authentication/ForgotPassword";
import Profile from "./Pages/Profile";
import ResetPassword from "./Pages/Authentication/ResetPassword";
import Students from "./Pages/Students/Main";
import ResetQuestion from "./Pages/ResetQuestion";
import ResetButtons from "./Pages/ResetButtons";
import AddPoint from "./Pages/AddPoint";
import GetAnswers from "./Pages/GetAnswers";
import Ambassadors from "./Pages/Ambassadors/Main";
import AddPointAmbassador from "./Pages/AddPointAmbassador";

function App() {
  if (process.env.NODE_ENV === "production") {
    console.log = function () {};
  }

  return (
    <AppProvider>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/profile" component={Profile} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/password/reset" component={ResetPassword} />
          <Route path="/admin/students" component={Students} />
          <Route path="/admin/reset-question" component={ResetQuestion} />
          <Route path="/admin/reset-buttons" component={ResetButtons} />
          <Route path="/admin/add-point-student" component={AddPoint} />
          <Route path="/admin/get-answers" component={GetAnswers} />
          <Route path="/admin/ambassadors" component={Ambassadors} />
          <Route
            path="/admin/add-point-ambassador"
            component={AddPointAmbassador}
          />
        </Switch>
      </Router>
    </AppProvider>
  );
}
export default App;
