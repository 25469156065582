import React, { useCallback, useState, useEffect } from "react";
import {
  DropZone,
  Page,
  FormLayout,
  TextField,
  Card,
  PageActions,
  Stack,
  Thumbnail,
  Caption,
  TextStyle,
  Loading,
  InlineError,
  Select,
  Checkbox,
} from "@shopify/polaris";
import axios from "../../Assets/Lib/axios";
import history from "../../Assets/Lib/history";
import { useParams } from "react-router-dom";
const Edit = () => {
  const { id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const [hoverImagePreview, setHoverImagePreview] = useState("");
  const [normalImagePreview, setNormalImagePreview] = useState("");
  const [hoverImageError, setHoverImageError] = useState("");
  const [normalImageError, setNormalImageError] = useState("");
  const [files, setFiles] = useState([]);
  const handleDropZone = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );
  const fileUpload = !files.length && <DropZone.FileUpload />;
  let uploadedFiles = files.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={files[files.length - 1].name}
        source={
          validImageTypes.indexOf(files[files.length - 1].type) > 0
            ? window.URL.createObjectURL(files[files.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {files[files.length - 1].name}{" "}
        <Caption>{files[files.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImage = (files) => {
    setHoverImagePreview(
      validImageTypes.indexOf(files[files.length - 1].type) > 0
        ? window.URL.createObjectURL(files[files.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setHoverImageError("");
  };

  const [filesNormal, setFilesNormal] = useState([]);
  const handleDropZoneNormal = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFilesNormal((filesNormal) => [...filesNormal, ...acceptedFiles]),
    []
  );
  const fileUploadNormal = !filesNormal.length && <DropZone.FileUpload />;
  let uploadedFilesNormal = filesNormal.length > 0 && (
    <Stack alignment="center">
      <Thumbnail
        size="small"
        alt={filesNormal[filesNormal.length - 1].name}
        source={
          validImageTypes.indexOf(filesNormal[filesNormal.length - 1].type) > 0
            ? window.URL.createObjectURL(filesNormal[filesNormal.length - 1])
            : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
        }
      />
      <div>
        {filesNormal[filesNormal.length - 1].name}{" "}
        <Caption>{filesNormal[filesNormal.length - 1].type} bytes</Caption>
      </div>
    </Stack>
  );
  const sendImageNormal = (filesNormal) => {
    setNormalImagePreview(
      validImageTypes.indexOf(filesNormal[filesNormal.length - 1].type) > 0
        ? window.URL.createObjectURL(filesNormal[filesNormal.length - 1])
        : "https://cdn.shopify.com/s/files/1/0757/9955/files/New_Post.png?12678548500147524304"
    );
    setNormalImageError("");
    setMessageError("");
  };
  const [checkedShow, setCheckedShow] = useState(true);
  const handleChangeShow = useCallback(
    (newChecked) => setCheckedShow(newChecked),
    []
  );
  const [titleEnValue, setTitleEnValue] = useState("");
  const handleChangeTitleEn = useCallback((newValue) => {
    setTitleEnValue(newValue);
  }, []);
  const [titleFrValue, setTitleFrValue] = useState("");
  const handleChangeTitleFr = useCallback((newValue) => {
    setTitleFrValue(newValue);
  }, []);

  const [nameValue, setNameValue] = useState("");
  const handleChangeName = useCallback((newValue) => {
    setItem({ ...item, name: newValue });
    setNameError("");
    setMessageError("");
  });
  const [nameError, setNameError] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const handleSelectChangeGroup = useCallback((value) => {
    setItem({ ...item, group: value });
    setGroupError("");
    setMessageError("");
  });
  const optionsGroup = [
    { label: "Group 1", value: "1" },
    { label: "Group 2", value: "2" },
    { label: "Group 3", value: "3" },
    { label: "Group 4", value: "4" },
  ];

  const [selectedPlayer, setSelectedPlayer] = useState("");
  const handleSelectChangePlayer = useCallback((value) => {
    setItem({ ...item, player: value });
    setPlayerError("");
    setMessageError("");
  });
  const optionsPlayer = [
    { label: "Player 1", value: "1" },
    { label: "Player 2", value: "2" },
    { label: "Player 3", value: "3" },
    { label: "Player 4", value: "4" },
    { label: "Player 5", value: "5" },
    { label: "Player 6", value: "6" },
    { label: "Player 7", value: "7" },
    { label: "Player 8", value: "8" },
  ];
  const [playerError, setPlayerError] = useState("");
  const [groupError, setGroupError] = useState("");
  const [messageError, setMessageError] = useState("");
  const handleChangePhase1ScoreValue = useCallback((newValue) => {
    setItem({ ...item, phase1Score: newValue });
    setMessageError("");
  });
  const handleChangePhase3ScoreValue = useCallback((newValue) => {
    setItem({ ...item, phase3Score: newValue });
    setMessageError("");
  });
  const handleChangeWinner = useCallback((newValue) => {
    setItem({ ...item, checkedWinner: newValue });
  });
  const handleChangeSchool = useCallback((newValue) => {
    setItem({ ...item, school: newValue });
  });
  const [item, setItem] = useState({
    image: "",
    name: "",
    group: "",
    player: "",
    phase1Score: "",
    phase3Score: "",
    checkedWinner: "",
    school: "",
  });
  useEffect(() => {
    axios
      .get(`admin/v1/students/${id}`)
      .then((result) => {
        setIsSaving(false);
        setItem({
          image: result?.data?.data?.image ? result.data.data.image : "",
          name: result?.data?.data?.name ? result.data.data.name : "",
          group:
            result?.data?.data?.group_id !== null
              ? String(result.data.data.group_id)
              : "",
          player:
            result?.data?.data?.player_id !== null
              ? String(result.data.data.player_id)
              : "",
          phase1Score:
            result?.data?.data?.phase1_score !== null
              ? String(result.data.data.phase1_score)
              : "",
          phase3Score:
            result?.data?.data?.phase3_score !== null
              ? String(result.data.data.phase3_score)
              : "",
          checkedWinner: result?.data?.data?.phase1_winner ? true : false,
          school: result?.data?.data?.school ? result.data.data.school : "",
        });
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Page title="Edit Student" breadcrumbs={[{ url: "/admin/students" }]}>
      {isSaving ? <Loading /> : null}
      <Card sectioned>
        <FormLayout>
          <FormLayout.Group>
            <FormLayout>
              <TextStyle variation="strong">Image</TextStyle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <div style={{ float: "left" }}>
                  <Thumbnail
                    size="large"
                    source={item.image ? item.image : ""}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <DropZone
                    onDrop={handleDropZoneNormal}
                    onDropAccepted={sendImageNormal}
                  >
                    {uploadedFilesNormal}
                    {fileUploadNormal}
                  </DropZone>
                </div>
              </div>
              <InlineError message={normalImageError} />
              <TextStyle variation="strong">Name</TextStyle>
              <TextField
                value={item.name}
                onChange={handleChangeName}
                autoComplete="off"
                error={nameError}
              />
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Group</TextStyle>
                  <Select
                    options={optionsGroup}
                    onChange={handleSelectChangeGroup}
                    value={item.group}
                    error={groupError}
                    placeholder="Please select a group"
                  />
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Player</TextStyle>
                  <Select
                    options={optionsPlayer}
                    onChange={handleSelectChangePlayer}
                    value={item.player}
                    error={playerError}
                    placeholder="Please select a player"
                  />
                </FormLayout>
              </FormLayout.Group>
              <FormLayout.Group>
                <FormLayout>
                  <TextStyle variation="strong">Phase 1 Score</TextStyle>
                  <TextField
                    value={item.phase1Score}
                    onChange={handleChangePhase1ScoreValue}
                    autoComplete="off"
                  />
                </FormLayout>
                <FormLayout>
                  <TextStyle variation="strong">Phase 3 Score</TextStyle>
                  <TextField
                    value={item.phase3Score}
                    onChange={handleChangePhase3ScoreValue}
                    autoComplete="off"
                  />
                </FormLayout>
              </FormLayout.Group>
              <TextStyle variation="strong">School</TextStyle>
              <TextField
                value={item.school}
                onChange={handleChangeSchool}
                autoComplete="off"
              />
              <InlineError message={messageError} />
            </FormLayout>
          </FormLayout.Group>
          <Checkbox
            label="Phase 1 Winner"
            checked={item.checkedWinner}
            onChange={handleChangeWinner}
          />
        </FormLayout>
      </Card>
      <PageActions
        primaryAction={{
          content: "Save",
          onClick: handleSave,
          loading: isSaving && true,
        }}
        secondaryActions={[
          {
            content: "Delete",
            destructive: true,
            loading: isSaving && true,
            onClick: () => {
              setIsSaving(true);
              axios
                .delete(`admin/v1/students/${id}`)
                .then((result) => {
                  history.push(`/admin/students`);
                })
                .catch((err) => console.log(err));
            },
          },
        ]}
      />
    </Page>
  );

  function handleSave() {
    if (!item.name) {
      !nameValue && setNameError("Please enter a name");
    } else {
      setIsSaving(true);
      const form_data = new FormData();
      filesNormal.length &&
        form_data.append("image", filesNormal[filesNormal.length - 1]);
      item.name && form_data.append("name", item.name);
      item.group !== "" && form_data.append("group_id", item.group);
      item.player !== "" && form_data.append("player_id", item.player);
      item.phase1Score !== "" &&
        form_data.append("phase1_score", item.phase1Score);
      item.phase3Score !== "" &&
        form_data.append("phase3_score", item.phase3Score);
      form_data.append("phase1_winner", item.checkedWinner ? 1 : 0);
      form_data.append("_method", "PATCH");
      item.school && form_data.append("school", item.school);
      axios
        .post(`admin/v1/students/${id}`, form_data)
        .then((res) => {
          console.log(res.data);
          if (res.data.success === true) {
            history.push("/admin/students");
          } else {
            setIsSaving(false);
            setMessageError(res.data.data);
          }
        })
        .catch((err) => console.log(""));
    }
  }
};
export default Edit;
